<template lang="pug">
  .competitor-row
    .by-dates(
      v-for="date in formattedDates"
      :key="date"
    )
      .price {{ getPrice(competitor, rentalPeriod, date) || "——" }}
      .plan-price(
        v-if="toggler.state"
        v-for="plan in plans"
        :key="`${competitor.id}_${plan.id}_${plan.name}`"
      ) {{ plan.prices[date] || "——" }}
</template>

<script>
  import { get, isEmpty } from "lodash-es"
  import { toggleize } from "@/helpers/toggler"

  export default {
    props: {
      competitor: Object,
      formattedDates: Array,
      rentalPeriod: String
    },

    computed: {
      plans({ competitor, rentalPeriod }) {
        return competitor.price_plans[rentalPeriod]
      },

      toggler({ rentalPeriod, competitor }) {
        return toggleize(`toggler-${rentalPeriod}-${competitor.id}`, false)
      }
    },

    methods: {
      isEmpty,

      getPrice(competitor, rentalPeriod, date) {
        return get(competitor, `prices.${rentalPeriod}.${date}`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .competitor-row
    display: flex
    font-size: 0.8em

    .price,
    .plan-price
      align-items: center
      display: flex
      height: 88px
      justify-content: center
      width: 90px

    .plan-price
      background-color: $default-gray-light
      color: $default-gray

    .no-price-plans
      width: 90px
      height: 50px
</style>
